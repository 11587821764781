"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
var React = require("react");
var PropTypes = require("prop-types");
function warn(warning) {
    if (process.env.NODE_ENV !== "production") {
        console.warn(warning); // eslint-disable-line no-console
    }
}
function some(array, predicate) {
    return array.filter(predicate).length > 0;
}
/** A powerful React component to abstract over flexbox and create any layout on any browser */
var FlexView = /** @class */ (function (_super) {
    __extends(FlexView, _super);
    function FlexView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FlexView.prototype.componentDidMount = function () {
        this.logWarnings();
    };
    FlexView.prototype.logWarnings = function () {
        var _a = this.props, basis = _a.basis, shrink = _a.shrink, grow = _a.grow, hAlignContent = _a.hAlignContent, vAlignContent = _a.vAlignContent, children = _a.children, column = _a.column;
        if (basis === "auto") {
            warn('basis is "auto" by default: forcing it to "auto"  will leave "shrink:true" as default');
        }
        if ((shrink === false || shrink === 0) &&
            (grow === true || (typeof grow === "number" && grow > 0))) {
            warn('passing both "grow" and "shrink={false}" is a no-op!');
        }
        if (process.env.NODE_ENV !== "production" &&
            typeof children !== "undefined" &&
            !column &&
            hAlignContent === "center") {
            var atLeastOneChildHasHMarginAuto = some([].concat(children), function (child) {
                var props = (typeof child === "object" && child !== null
                    ? child.props
                    : undefined) || {};
                var style = props.style || {};
                var marginLeft = style.marginLeft || props.marginLeft;
                var marginRight = style.marginRight || props.marginRight;
                return marginLeft === "auto" && marginRight === "auto";
            });
            atLeastOneChildHasHMarginAuto &&
                warn('In a row with hAlignContent="center" there should be no child with marginLeft and marginRight set to "auto"\nhttps://github.com/buildo/react-flexview/issues/30');
        }
        if (process.env.NODE_ENV !== "production" &&
            typeof children !== "undefined" &&
            column &&
            vAlignContent === "center") {
            var atLeastOneChildHasVMarginAuto = some([].concat(children), function (child) {
                var props = (typeof child === "object" && child !== null
                    ? child.props
                    : undefined) || {};
                var style = props.style || {};
                var marginTop = style.marginTop || props.marginTop;
                var marginBottom = style.marginBottom || props.marginBottom;
                return marginTop === "auto" && marginBottom === "auto";
            });
            atLeastOneChildHasVMarginAuto &&
                warn('In a column with vAlignContent="center" there should be no child with marginTop and marginBottom set to "auto"\nhttps://github.com/buildo/react-flexview/issues/30');
        }
    };
    FlexView.prototype.getGrow = function () {
        var grow = this.props.grow;
        if (typeof grow === "number") {
            return grow;
        }
        else if (grow) {
            return 1;
        }
        return 0; // default
    };
    FlexView.prototype.getShrink = function () {
        var _a = this.props, shrink = _a.shrink, basis = _a.basis;
        if (typeof shrink === "number") {
            return shrink;
        }
        else if (shrink) {
            return 1;
        }
        else if (shrink === false) {
            return 0;
        }
        if (basis && basis !== "auto") {
            return 0;
        }
        return 1; // default
    };
    FlexView.prototype.getBasis = function () {
        var basis = this.props.basis;
        if (basis) {
            var suffix = typeof basis === "number" ||
                String(parseInt(basis, 10)) === basis
                ? "px"
                : "";
            return basis + suffix;
        }
        return "auto"; // default
    };
    FlexView.prototype.getStyle = function () {
        var _a = this.props, column = _a.column, wrap = _a.wrap, vAlignContent = _a.vAlignContent, hAlignContent = _a.hAlignContent;
        var style = {
            width: this.props.width,
            height: this.props.height,
            marginLeft: this.props.marginLeft,
            marginTop: this.props.marginTop,
            marginRight: this.props.marginRight,
            marginBottom: this.props.marginBottom
        };
        function alignPropToFlex(align) {
            switch (align) {
                case "top":
                case "left":
                    return "flex-start";
                case "center":
                    return "center";
                case "bottom":
                case "right":
                    return "flex-end";
            }
        }
        return __assign(__assign({ boxSizing: "border-box", 
            // some browsers don't set these by default on flex
            minWidth: 0, minHeight: 0, 
            // flex properties
            display: "flex", flexDirection: column ? "column" : "row", flexWrap: wrap ? "wrap" : "nowrap", flex: this.getGrow() + " " + this.getShrink() + " " + this.getBasis(), justifyContent: alignPropToFlex(column ? vAlignContent : hAlignContent), alignItems: alignPropToFlex(column ? hAlignContent : vAlignContent) }, style), this.props.style);
    };
    FlexView.prototype.getDivProps = function () {
        var _a = this.props, children = _a.children, className = _a.className, style = _a.style, column = _a.column, grow = _a.grow, shrink = _a.shrink, basis = _a.basis, wrap = _a.wrap, vAlignContent = _a.vAlignContent, hAlignContent = _a.hAlignContent, width = _a.width, height = _a.height, marginBottom = _a.marginBottom, marginTop = _a.marginTop, marginLeft = _a.marginLeft, marginRight = _a.marginRight, rest = __rest(_a, ["children", "className", "style", "column", "grow", "shrink", "basis", "wrap", "vAlignContent", "hAlignContent", "width", "height", "marginBottom", "marginTop", "marginLeft", "marginRight"]);
        return rest;
    };
    FlexView.prototype.render = function () {
        return (React.createElement("div", __assign({ className: this.props.className, style: this.getStyle() }, this.getDivProps()), this.props.children));
    };
    FlexView.propTypes = {
        children: PropTypes.node,
        column: PropTypes.bool,
        vAlignContent: PropTypes.oneOf(["top", "center", "bottom"]),
        hAlignContent: PropTypes.oneOf(["left", "center", "right"]),
        marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        grow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        shrink: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        basis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        wrap: PropTypes.bool,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        className: PropTypes.string,
        style: PropTypes.object
    };
    return FlexView;
}(React.Component));
exports.FlexView = FlexView;
exports["default"] = FlexView;
